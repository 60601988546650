import styles from './SubstitutionForm.module.scss';
import { EmptyState, Grid, GridColumn, GridRow, Input, Select, SelectOptionType } from '@bp/ui-components';
import { ModalBottomButtons } from '../../ModalBottomButtons/ModalBottomButtons';
import { useLoadBasicData } from '../../../hooks/useLoadBasicData';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { SubstitutionFormRow, TeacherSubjectFormRowType } from './SubstitutionFormRow';
import { useTranslation } from 'react-i18next';
import { useLayoutEffect, useRef, useState } from 'react';
import {
  getMinutesDifference,
  isFirstSameOrAfterSecond,
  isFirstSameOrBeforeSecond,
  normalizedDate,
} from '../../../utils/dateCalculations';
import { SubstitutionDataEntry } from '../../../pages/Substitutions/Plan/PlanSubstitutions/PlanSubstitutions';
import { useSubstitutionStore } from '../SubstitutionProvider';
import { SubstitutionsSubjectsTableType } from '../tables/SubstitutionSubjectsTable';
import { useCreateSelectOptions } from '../../../hooks/useCreateSelectOptions';
import { Form, Formik, FormikHelpers } from 'formik';
import { MultiValue } from 'react-select';

type SubstitutionFormProps = {
  originTeachers: SubstitutionDataEntry[];
  originRooms: SubstitutionDataEntry[];
  timetableStart: Date;
  timetableEnd: Date;
  subjectStart: Date;
  subjectEnd: Date;
  substitutionSubjects: SubstitutionsSubjectsTableType[];
  onClose: () => void;
};

type SubstitutionFormType = {
  action: string;
  roomUuids: string[];
  comment: string;
};

export const SubstitutionForm = ({
  originTeachers,
  originRooms,
  timetableStart,
  timetableEnd,
  subjectStart,
  subjectEnd,
  substitutionSubjects,
  onClose,
}: SubstitutionFormProps) => {
  const { t } = useTranslation();

  const { selectedDay } = useSubstitutionStore();
  const { teacherData, roomsData } = useLoadBasicData({ pause: false });

  const initialValues: SubstitutionFormType = {
    action: '',
    roomUuids: [],
    comment: '',
  };

  const hourRef = useRef<HTMLDivElement>(null);
  const [hourWidth, setHourWidth] = useState<number>(0);

  const startHour = dayjs(timetableStart).hour();
  const endHour = dayjs(timetableEnd).hour();
  const hours = Array.from({ length: endHour - startHour + 1 }, (_, i) =>
    dayjs()
      .set('hour', startHour + i)
      .startOf('hour'),
  );

  const actionsOpts: SelectOptionType[] = [];
  const availableRoomsOpts: SelectOptionType[] = useCreateSelectOptions(roomsData.rooms, 'uuid', 'name');

  const frames: { left: number; width: number }[] = [];

  const normalizedSubjectStart = normalizedDate(subjectStart, selectedDay);
  const normalizedSubjectEnd = normalizedDate(subjectEnd, selectedDay);

  const isBefore = isFirstSameOrBeforeSecond(normalizedSubjectStart, timetableStart, 'minutes');
  const isAfter = isFirstSameOrAfterSecond(normalizedSubjectEnd, timetableEnd, 'minutes');

  const subjectDuration = getMinutesDifference(normalizedSubjectStart, normalizedSubjectEnd);
  const subjectWidth = (hourWidth / 60) * subjectDuration;

  const startDuration = isBefore ? 0 : getMinutesDifference(normalizedSubjectStart, timetableStart);
  const startWidth = (hourWidth / 60) * startDuration;

  const endDuration = isAfter ? 0 : getMinutesDifference(normalizedSubjectEnd, timetableEnd);
  const endWidth = (hourWidth / 60) * endDuration;

  frames.push(
    {
      left: 0,
      width: startWidth,
    },
    {
      left: startWidth + subjectWidth,
      width: endWidth,
    },
  );

  async function handleSubmit(values: SubstitutionFormType, formHelpers: FormikHelpers<SubstitutionFormType>) {
    // TODO: implement
  }

  useLayoutEffect(() => {
    if (hourRef.current) {
      setHourWidth(hourRef.current.getBoundingClientRect().width);
    }
  }, []);

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ setFieldValue, values, isSubmitting, dirty, resetForm, errors }) => {
        return (
          <Form className={styles['substitution-form']}>
            <div className={styles.info}>
              <div>
                {t('substitutions.initialTeacher')}:
                <span className={styles.bold}>
                  {originTeachers.length > 0 ? originTeachers.map((t) => t.name).join(', ') : t('common.none')}
                </span>
              </div>
              <div>
                {t('substitutions.initialRooms')}:
                <span className={styles.bold}>
                  {originRooms.length > 0 ? originRooms.map((r) => r.name).join(', ') : t('common.none')}
                </span>
              </div>
            </div>

            <Grid useFormGap>
              <GridRow spacingBottom='none'>
                <GridColumn width={6}>
                  <Select
                    name='actions'
                    isSearchable
                    label={t('substitutions.action')}
                    // value={actionsOpts.find((r) => r.value === values?.teacherUuid)}
                    options={actionsOpts}
                    onChange={async (option) => {
                      // const opt = option as SingleValue<SelectOptionType>;
                      // await setFieldValue('teacherUuid', opt?.value);
                    }}
                    //   error={errors.teacherUuid}
                  />
                </GridColumn>
                <GridColumn width={6}>
                  <Select
                    label={t('substitutions.availableRooms')}
                    onChange={async (option) => {
                      const multiValue = option as MultiValue<SelectOptionType>;
                      await setFieldValue(
                        'roomUuids',
                        multiValue.map((value) => value.value),
                      );
                    }}
                    isMulti
                    isClearable
                    name='available-rooms'
                    value={availableRoomsOpts.find((r) => r.value === values?.roomUuids)}
                    options={availableRoomsOpts}
                    error={errors.roomUuids as string}
                  />
                </GridColumn>
              </GridRow>
              <GridRow spacingTop='none'>
                <Input
                  name='comment'
                  value={initialValues.comment}
                  label={t('common.comment')}
                  onChange={async (e) => {
                    // await setFieldValue('comment', e.target.value);
                  }}
                  // error={errors.comment}
                />
              </GridRow>
            </Grid>

            <div className={styles.substitutions}>
              <div className={styles.header}>
                <div className={styles.teachers}>
                  <div className={styles.tiny}></div>
                  <div className={styles.big}>{t('persons.title', { count: 1 })}</div>
                  <div className={styles.small}>{t('common.value')}</div>
                  <div className={styles.small}>{t('substitutions.target')}</div>
                  <div className={styles.small}>{t('substitutions.current')}</div>
                  <div className={styles.medium}>{t('substitutions.sameClass')}</div>
                  <div className={styles.medium}>{t('substitutions.sameSubject')}</div>
                </div>
                <div className={styles.hours} style={{ gridTemplateColumns: `repeat(${hours.length}, 1fr)` }}>
                  {hours.map((hour, index) => {
                    return (
                      <div
                        key={hour.toString()}
                        ref={index === 0 ? hourRef : undefined}
                        className={styles.hour}
                      >{`${String(hour.hour()).padStart(2, '0')}:00`}</div>
                    );
                  })}
                </div>
              </div>

              <div className={classNames(styles.rows, 'has-scrollbar')}>
                {teacherData && teacherData.people ? (
                  teacherData?.people.map((t) => {
                    const subjects: TeacherSubjectFormRowType[] = [];

                    for (const substitutionSubject of substitutionSubjects) {
                      if (substitutionSubject.teachers.some((teacher) => teacher.uuid === t.uuid)) {
                        subjects.push({
                          start: substitutionSubject.start,
                          end: substitutionSubject.end,
                          name: substitutionSubject.subject.shortName,
                          status: 'normal',
                        });
                      }
                    }

                    return (
                      <SubstitutionFormRow
                        key={t.uuid}
                        hours={hours}
                        hourWidth={hourWidth}
                        frames={frames}
                        teacherUuid={t.uuid}
                        teacherSubjects={subjects}
                      />
                    );
                  })
                ) : (
                  <EmptyState hideIcon title={t('substitutions.noTeachers')} forcedHeight='10vh' />
                )}
              </div>
            </div>

            <div className={styles.footer}>
              <div className={styles.legend}>
                <div className={styles.colors}>
                  <div>{t('substitutions.normalLesson')}</div>
                  <div>{t('substitutions.attendance')}</div>
                  <div>{t('substitutions.cancellationClassTrip')}</div>
                  <div>{t('substitutions.title', { count: 1 })}</div>
                  <div>{t('substitutions.onClassTrip')} *</div>
                  <div>{t('substitutions.signedOut')} *</div>
                </div>
                <div className={styles.hint}>* = {t('substitutions.fullDay')}</div>
              </div>

              <ModalBottomButtons
                closeButton={{
                  callback: () => {
                    onClose();
                    resetForm();
                  },
                }}
                submitButton={{
                  disabled: isSubmitting || !dirty,
                }}
                className={styles.bottom}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
