import {
  Button,
  Checkbox,
  DotsHorizontalIcon,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  Row,
  RowSelectionState,
  Table,
  TableColumns,
  Tooltip,
} from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useColumnsSort } from '../../../hooks/useColumnsSort';
import { useHiddenColumns } from '../../../hooks/useHiddenColumns';
import dayjs from 'dayjs';
import { useSubstitutionStore } from '../SubstitutionProvider';
import { SubstitutionDataEntry } from '../../../pages/Substitutions/Plan/PlanSubstitutions/PlanSubstitutions';

type SubstitutionSubjectsTableProps = {
  data: SubstitutionsSubjectsTableType[];
  onRowSelect: (classes: SubstitutionDataEntry[]) => void;
  onSubstitute: (uuid: string) => void;
  onCancel: (uuid: string) => void;
  onCompleteClass: (uuid: string) => void;
  onWorkOrder: (uuid: string) => void;
  onDifferentTime: (uuid: string) => void;
};

export type SubstitutionsSubjectsTableType = {
  output: boolean;
  uuid: string;
  start: Date;
  end: Date;
  classes: SubstitutionDataEntry[];
  groups: SubstitutionDataEntry[];
  subject: SubstitutionDataEntry;
  rooms: SubstitutionDataEntry[];
  teachers: SubstitutionDataEntry[];
  missingTeachers: SubstitutionDataEntry[];
  substitutionTeachers: SubstitutionDataEntry[];
  action: string;
  reason: string;
  comment: string;
};

export const SubstitutionSubjectsTable = ({
  data,
  onRowSelect,
  onSubstitute,
  onCancel,
  onCompleteClass,
  onWorkOrder,
  onDifferentTime,
}: SubstitutionSubjectsTableProps) => {
  const { t } = useTranslation();
  const { selectedDay } = useSubstitutionStore();

  const [selected, setSelected] = useState<RowSelectionState>({});
  const { sorting, saveSorting } = useColumnsSort('substitution-subjects');
  const { columnVisibility, saveColumnVisibility } = useHiddenColumns('substitution-subjects', {});

  const columns: TableColumns<SubstitutionsSubjectsTableType>[] = [
    {
      id: 'output',
      header: t('substitutions.print'),
      size: 40,
      cell: ({ row }) => {
        return (
          <Checkbox
            name={`print-${row.original.uuid}`}
            onChange={() => {
              // TODO: implement
            }}
            checked={row.original.output}
          />
        );
      },
    },
    {
      id: 'timeframe',
      header: t('common.time'),
      isMultiline: true,
      cell: ({ row }) => {
        return (
          <>
            {dayjs(row.original.start).format('HH:mm')}-&#8203;{dayjs(row.original.end).format('HH:mm')}
          </>
        );
      },
    },
    {
      id: 'classes',
      header: t('classes.title', { count: 2 }),
      cell: ({ row }) => {
        return (
          <Tooltip triggerStyle={{ display: 'inline' }} content={row.original.classes.map((c) => c.name).join(', ')}>
            {row.original.classes.map((c) => c.shortName).join(', ')}
          </Tooltip>
        );
      },
    },
    {
      id: 'groups',
      header: t('groups.title', { count: 2 }),
      cell: ({ row }) => {
        return (
          <Tooltip triggerStyle={{ display: 'inline' }} content={row.original.groups.map((g) => g.name).join(', ')}>
            {row.original.groups.map((g) => g.shortName).join(', ')}
          </Tooltip>
        );
      },
    },
    {
      id: 'subjects',
      header: t('subject.title', { count: 2 }),
      cell: ({ row }) => {
        return (
          <Tooltip triggerStyle={{ display: 'inline' }} content={row.original.subject.name}>
            {row.original.subject.shortName}
          </Tooltip>
        );
      },
    },
    {
      id: 'rooms',
      header: t('rooms.title', { count: 2 }),
      cell: ({ row }) => {
        return (
          <Tooltip triggerStyle={{ display: 'inline' }} content={row.original.rooms.map((r) => r.name).join(', ')}>
            {row.original.rooms.map((r) => r.shortName).join(', ')}
          </Tooltip>
        );
      },
    },
    {
      id: 'missingTeachers',
      header: t('substitutions.missing'),
      cell: ({ row }) => {
        return (
          <Tooltip
            triggerStyle={{ display: 'inline' }}
            content={row.original.missingTeachers.map((t) => t.name).join(', ')}
          >
            {row.original.missingTeachers.map((t) => t.shortName).join(', ')}
          </Tooltip>
        );
      },
    },
    {
      id: 'substitutionTeachers',
      header: t('substitutions.title', { count: 1 }),
      cell: ({ row }) => {
        return (
          <Tooltip
            triggerStyle={{ display: 'inline' }}
            content={row.original.substitutionTeachers.map((t) => t.name).join(', ')}
          >
            {row.original.substitutionTeachers.map((t) => t.shortName).join(', ')}
          </Tooltip>
        );
      },
    },
    {
      id: 'action',
      header: t('substitutions.action'),
    },
    {
      id: 'reason',
      header: t('absences.reason'),
    },
    {
      id: 'comment',
      header: t('common.comment'),
    },
  ];

  const menu = (row: Row<SubstitutionsSubjectsTableType>): DropdownMenuItem[] => {
    return [
      {
        label: t('substitutions.title', { count: 1 }),
        onClick: (e) => {
          e.stopPropagation();
          onSubstitute(row.original.uuid);
        },
      },
      {
        label: t('substitutions.cancel'),
        onClick: (e) => {
          e.stopPropagation();
          onCancel(row.original.uuid);
        },
      },
      {
        label: t('substitutions.completeClass'),
        onClick: (e) => {
          e.stopPropagation();
          onCompleteClass(row.original.uuid);
        },
      },
      {
        label: t('substitutions.workOrder'),
        onClick: (e) => {
          e.stopPropagation();
          onWorkOrder(row.original.uuid);
        },
      },
      {
        label: t('substitutions.differentTime'),
        onClick: (e) => {
          e.stopPropagation();
          onDifferentTime(row.original.uuid);
        },
      },
      {
        type: 'ruler',
      },
      {
        label: t('substitutions.reset'),
        color: 'error',
        onClick: (e) => {
          e.stopPropagation();
          // TODO: implement
        },
        disabled: true,
      },
    ];
  };

  useEffect(() => {
    setSelected({});
    onRowSelect([]);
  }, [selectedDay]);

  return (
    <Table<SubstitutionsSubjectsTableType>
      columns={columns}
      data={data}
      showBorderRadius
      showShadow
      rowSelection={selected}
      onRowClick={(event, row) => {
        const isSelected = selected[row.id];
        setSelected({ [row.id]: !isSelected });
        onRowSelect(isSelected ? [] : row.original.classes);
      }}
      showRowHover
      selectOnClick
      showSelected
      showSort
      sorting={sorting}
      onSortingChange={saveSorting}
      showVisibility
      columnVisibility={columnVisibility}
      onColumnVisibilityChange={saveColumnVisibility}
      pageSize={15}
      showPagination={true}
      lastColWidth='36px'
      lastCol={(row) => {
        return (
          <Dropdown trigger={<Button hierarchy='secondary' icon={<DotsHorizontalIcon />} />}>
            <DropdownMenu data={menu(row)} />
          </Dropdown>
        );
      }}
    />
  );
};
