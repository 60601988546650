import { Modal } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { SubstitutionDataEntry } from '../../../pages/Substitutions/Plan/PlanSubstitutions/PlanSubstitutions';
import { CompleteClassForm } from '../forms/CompleteClassForm';

type CompleteClassModalProps = {
  isOpen: boolean;
  originTeachers: SubstitutionDataEntry[];
  originRooms: SubstitutionDataEntry[];
  onClose: () => void;
};

export const CompleteClassModal = ({ isOpen, originTeachers, originRooms, onClose }: CompleteClassModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal title={t('substitutions.completeClass')} isOpen={isOpen} onRequestClose={onClose}>
      <CompleteClassForm originTeachers={originTeachers} originRooms={originRooms} onClose={onClose} />
    </Modal>
  );
};
