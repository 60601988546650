import { Outlet } from 'react-router-dom';
import { SubstitutionsTopBar } from '../../../components/SubstitutionsTopBar/SubstitutionsTopBar';
import { Page } from '../../../components/Page/Page';
import { SubstitutionProvider } from '../../../components/Substitutions/SubstitutionProvider';

type PlanSubstitutionsProps = {
  baseSlug: string;
};

export const SubstitutionsPlanSubpage = ({ baseSlug }: PlanSubstitutionsProps) => {
  return (
    <Page title={null}>
      <SubstitutionProvider>
        <SubstitutionsTopBar baseSlug={baseSlug} />
        <Outlet />
      </SubstitutionProvider>
    </Page>
  );
};
