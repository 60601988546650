import { Modal } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { CancelSubjectForm } from '../forms/CancelSubjectForm';

type CancelSubjectModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const CancelSubjectModal = ({ isOpen, onClose }: CancelSubjectModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal width='s' title={t('substitutions.cancel')} isOpen={isOpen} onRequestClose={onClose}>
      <CancelSubjectForm onClose={onClose} />
    </Modal>
  );
};
