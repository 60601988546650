import { Modal } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { SubstitutionForm } from '../forms/SubstitutionForm';
import { SubstitutionDataEntry } from '../../../pages/Substitutions/Plan/PlanSubstitutions/PlanSubstitutions';
import { SubstitutionsSubjectsTableType } from '../tables/SubstitutionSubjectsTable';

type SubstitutionModalProps = {
  isOpen: boolean;
  originTeachers: SubstitutionDataEntry[];
  originRooms: SubstitutionDataEntry[];
  timetableStart: Date;
  timetableEnd: Date;
  subjectStart: Date;
  subjectEnd: Date;
  substitutionSubjects: SubstitutionsSubjectsTableType[];
  onClose: () => void;
};

export const SubstitutionModal = ({
  isOpen,
  originTeachers,
  originRooms,
  timetableStart,
  timetableEnd,
  subjectStart,
  subjectEnd,
  substitutionSubjects,
  onClose,
}: SubstitutionModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal width='l' title={t('substitutions.substituteSubject')} isOpen={isOpen} onRequestClose={onClose}>
      <SubstitutionForm
        originTeachers={originTeachers}
        originRooms={originRooms}
        timetableStart={timetableStart}
        timetableEnd={timetableEnd}
        subjectStart={subjectStart}
        subjectEnd={subjectEnd}
        substitutionSubjects={substitutionSubjects}
        onClose={onClose}
      />
    </Modal>
  );
};
