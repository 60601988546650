import dayjs, { Dayjs } from 'dayjs';
import styles from './SubstitutionFormRow.module.scss';
import { useLoadBasicData } from '../../../hooks/useLoadBasicData';
import { useMemo, useState } from 'react';
import { Checkbox, CheckIcon, Input } from '@bp/ui-components';
import classNames from 'classnames';
import { getMinutesDifference } from '../../../utils/dateCalculations';

type SubstitutionFormRowProps = {
  hours: Dayjs[];
  hourWidth: number;
  frames: { left: number; width: number }[];
  teacherUuid: string;
  teacherSubjects: TeacherSubjectFormRowType[];
};

export type TeacherSubjectFormRowType = {
  start: Date;
  end: Date;
  name?: string;
  status: 'normal' | 'attendance' | 'cancelation-class-trip' | 'substitution' | 'on-class-trip' | 'signed-out';
};

type SubstitutionFormRowType = {
  uuid: string;
  selected: boolean;
  name: string;
  value: number;
  target: number;
  current: number;
  sameClass: boolean;
  sameSubject: boolean;
  subjects: TeacherSubjectFormRowType[];
};

export const SubstitutionFormRow = ({
  hours,
  hourWidth,
  frames,
  teacherUuid,
  teacherSubjects,
}: SubstitutionFormRowProps) => {
  const [selected, setSelected] = useState<boolean>(false);
  const [value, setValue] = useState<number>(0);
  const [valueError, setValueError] = useState<string | null>(null);

  const { teacherData } = useLoadBasicData({ pause: false });

  const teacher: SubstitutionFormRowType = useMemo(() => {
    const teacher = teacherData?.people.find((t) => t.uuid === teacherUuid);
    return {
      uuid: teacher?.uuid ?? '',
      selected: selected,
      name: teacher?.listName ?? '',
      value: 0,
      target: 0,
      current: 0,
      sameClass: false,
      sameSubject: false,
      subjects: teacherSubjects,
    };
  }, [selected]);

  return (
    <div className={classNames(styles['substitution-form-row'], { [styles.selected]: teacher.selected })}>
      <div className={styles.data}>
        <div className={styles.tiny}>
          <Checkbox
            checked={selected}
            name={`select-${teacher.uuid}`}
            onChange={(value) => setSelected(value.target.checked)}
          />
        </div>
        <div className={styles.big} title={teacher.name}>
          {teacher.name}
        </div>
        <div className={styles.small}>
          {
            <Input
              type='number'
              dense
              name={`value-${teacher.uuid}`}
              value={selected ? value : undefined}
              disabled={!selected}
              onChange={(event) => {
                const value = Number(event.target.value);
                if (!Number.isNaN(value) && value >= 0 && value < 99) {
                  setValue(value);
                  setValueError(null);
                } else {
                  setValueError('error');
                }
              }}
              className={styles['value-input']}
              error={valueError as string}
            />
          }
        </div>
        <div className={styles.small}>{teacher.target}</div>
        <div className={styles.small}>{teacher.current}</div>
        <div className={styles.medium}>{teacher.sameClass && <CheckIcon />}</div>
        <div className={styles.medium}>{teacher.sameSubject && <CheckIcon />}</div>
      </div>

      <div className={styles.hours} style={{ gridTemplateColumns: `repeat(${hours.length}, 1fr)` }}>
        {hours.map((hour) => {
          return <div key={hour.toString()} className={styles.hour}></div>;
        })}

        {hours.map((hour, hourIndex) => {
          return teacher.subjects
            .filter((subject) => {
              return dayjs(subject.start).hour() === hour.hour();
            })
            .map((subject) => {
              const difference = getMinutesDifference(hour, subject.start);
              const duration = getMinutesDifference(subject.start, subject.end);

              const offset = Math.ceil((hourWidth / 60) * difference + hourIndex * hourWidth);
              const width = Math.ceil((hourWidth / 60) * duration);

              return (
                <div
                  key={`${hour.toDate()}-${subject.start.toDateString()}`}
                  className={classNames(styles.subject, styles['status-' + subject.status])}
                  style={{ left: `${offset}px`, width: `${width - 1}px` }}
                  title={subject.name ?? undefined}
                >
                  {subject.name && (
                    <div className={styles.name} title={subject.name}>
                      {subject.name}
                    </div>
                  )}
                </div>
              );
            });
        })}

        {frames.map(({ left, width }, index) => {
          return (
            <div
              key={left + width + index}
              className={styles.frame}
              style={{ left: `${left}px`, width: `${width}px` }}
            ></div>
          );
        })}
      </div>
    </div>
  );
};
