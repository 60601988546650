import { useTranslation } from 'react-i18next';
import {
  SubstitutionsSubjectsTableType,
  SubstitutionSubjectsTable,
} from '../../../../components/Substitutions/tables/SubstitutionSubjectsTable';
import {
  ActionBar,
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  Card,
  Grid,
  GridColumn,
  GridRow,
  InvisibleIcon,
  TimetableView,
  TimetableViewEntryType,
  UploadIcon,
  VisibleIcon,
} from '@bp/ui-components';
import styles from './PlanSubstitutions.module.scss';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import {
  SortDirection,
  use_SchoolEventsQuery,
  useTimeGridEntriesByTimeGridQuery,
} from '../../../../types/planung-graphql-client-defs';
import { useMemorizedCacheTag } from '../../../../hooks/useMemorizedCacheTag';
import classNames from 'classnames';
import { SubstitutionModal } from '../../../../components/Substitutions/modals/SubstitutionModal';
import { normalizedDate } from '../../../../utils/dateCalculations';
import { useSubstitutionStore } from '../../../../components/Substitutions/SubstitutionProvider';
import { useLoadBasicData } from '../../../../hooks/useLoadBasicData';
import { observer } from 'mobx-react-lite';
import { CancelSubjectModal } from '../../../../components/Substitutions/modals/CancelSubjectModal';
import { WorkOrderModal } from '../../../../components/Substitutions/modals/WorkOrderModal';
import { CompleteClassModal } from '../../../../components/Substitutions/modals/CompleteClassModal';
import { DifferentTimeModal } from '../../../../components/Substitutions/modals/DifferentTimeModal';

export type SubstitutionDataEntry = {
  uuid: string;
  name: string;
  shortName: string;
  length?: number; // only used for group rendering in TimetableView
  index?: number; // only used for group rendering in TimetableView
};

export const PlanSubstitutions = observer(() => {
  const { t } = useTranslation();
  const timeGridContext = useMemorizedCacheTag('TIME_GRID');
  const context = useMemorizedCacheTag('EVENT');

  const [selectedClassEntries, setSelectedClassEntries] = useState<SubstitutionDataEntry[]>([]);
  const [selectedClassIndex, setSelectedClassIndex] = useState<number>(0);
  const [showAll, setShowAll] = useState<boolean>(false);

  const [selectedSubject, setSelectedSubject] = useState<SubstitutionsSubjectsTableType | null>(null);
  const [substitutionModalOpen, setSubstitutionModalOpen] = useState<boolean>(false);
  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false);
  const [completeClassModalOpen, setCompleteClassModalOpen] = useState<boolean>(false);
  const [workOrderModalOpen, setWorkOrderModalOpen] = useState<boolean>(false);
  const [differentTimeModalOpen, setDifferentTimeModalOpen] = useState<boolean>(false);

  const { selectedDay } = useSubstitutionStore();

  const [{ data: timeGridEntriesData }] = useTimeGridEntriesByTimeGridQuery({
    context: timeGridContext,
  });

  const timetableStart =
    timeGridEntriesData && timeGridEntriesData.timeGridEntries[0]
      ? dayjs(timeGridEntriesData?.timeGridEntries[0].start).startOf('hour')
      : dayjs().set('hour', 6);

  const timetableEnd =
    timeGridEntriesData && timeGridEntriesData.timeGridEntries[timeGridEntriesData.timeGridEntries.length - 1]
      ? dayjs(timeGridEntriesData?.timeGridEntries[timeGridEntriesData.timeGridEntries.length - 1].end).endOf('hour')
      : dayjs().set('hour', 18);

  // normalize date so that it is compareable
  const normalizedTimetableStart = normalizedDate(timetableStart, selectedDay);
  const normalizedTimetableEnd = normalizedDate(timetableEnd, selectedDay);

  const selectedDayStart = selectedDay.utc().startOf('day').toISOString();
  const selectedDayEnd = selectedDay.utc().endOf('day').toISOString();
  const [{ data: eventData }] = use_SchoolEventsQuery({
    variables: {
      options: {
        sort: [{ isSchoolEvent: SortDirection.Desc }, { start: SortDirection.Asc }],
      },
      where: {
        OR: [
          {
            start_GTE: selectedDayStart,
            end_LTE: selectedDayEnd,
          },
          {
            start_LTE: selectedDayStart,
            end_GTE: selectedDayEnd,
          },
          {
            AND: [
              {
                start_GTE: selectedDayStart,
                start_LTE: selectedDayEnd,
              },
            ],
          },
        ],
      },
    },
    context: context,
  });

  const { classesData, subjectData, teacherData, groupsData, divisionsData } = useLoadBasicData({
    pause: !eventData,
  });

  const substitutionSubjects: SubstitutionsSubjectsTableType[] = useMemo(() => {
    const subjects: SubstitutionsSubjectsTableType[] =
      eventData?.events.map((event) => {
        const subject = subjectData?.subjects.find((s) => s.uuid === event.subject.uuid);

        return {
          uuid: event.uuid,
          output: true,
          start: dayjs(event.start).toDate(),
          end: dayjs(event.end).toDate(),
          classes:
            classesData?.classes
              .filter((c) => event.classesOrGroups.map((cg) => cg.uuid).includes(c.uuid))
              .map((c) => {
                return {
                  uuid: c.uuid ?? '',
                  name: c.name ?? '',
                  shortName: c.shortName ?? '',
                };
              }) ?? [],
          groups: event.classesOrGroups
            .filter((cg) => cg.__typename === 'Group')
            .map((g) => {
              const group = groupsData?.groups.find((group) => group.uuid === g.uuid);
              const division = divisionsData?.divisions.find(
                (d) => d.uuid === group?.divisionConnection.edges[0].node.uuid,
              );
              const edge = division?.groupsConnection.edges.find((e) => e.node.uuid === group?.uuid);

              return {
                uuid: group?.uuid ?? '',
                name: `${group?.name} (${division?.class?.name})`,
                shortName: `${group?.shortName} (${division?.class?.shortName})`,
                length: division?.groupsConnection.totalCount,
                index: edge?.properties.order,
              };
            })
            .sort(),
          subject: {
            uuid: subject?.uuid ?? '',
            name: subject?.name ?? '',
            shortName: subject?.shortName ?? '',
          },
          rooms: [],
          teachers: event.persons.map((p) => {
            const teacher = teacherData?.people.find((t) => t.uuid === p.uuid);
            return {
              uuid: teacher?.uuid ?? '',
              name: teacher?.displayName ?? '',
              shortName: teacher?.shortName ?? '',
            };
          }),
          missingTeachers: [],
          substitutionTeachers: [],
          action: '',
          reason: '',
          comment: event.comment ?? '',
        };
      }) ?? [];

    return subjects;
  }, [eventData?.events, selectedDay]);

  const filteredSubstitutionSubjects: SubstitutionsSubjectsTableType[] = useMemo(() => {
    // TODO: implement
    return substitutionSubjects;
  }, [substitutionSubjects]);

  const timetableViewEntries: TimetableViewEntryType[] = useMemo(() => {
    if (!selectedClassEntries[selectedClassIndex]) return [];

    const entries: TimetableViewEntryType[] = [];
    const classSubjects = substitutionSubjects.filter((s) =>
      s.classes.some((c) => c.uuid === selectedClassEntries[selectedClassIndex].uuid),
    );

    for (const substitutionSubject of classSubjects) {
      const subject = subjectData?.subjects.find((s) => s.uuid === substitutionSubject.subject.uuid);

      if (substitutionSubject.groups.length > 0) {
        substitutionSubject.groups
          .filter((g) => {
            const selectedClass = classesData?.classes.find(
              (c) => c.uuid === selectedClassEntries[selectedClassIndex].uuid,
            );
            return g.shortName.includes(selectedClass?.shortName ?? '');
          })
          .forEach((group) => {
            entries.push({
              start: substitutionSubject.start,
              end: substitutionSubject.end,
              color: subject?.timetableConfig?.color ?? undefined,
              field1: subject?.shortName,
              field2: group.shortName,
              field4: substitutionSubject.teachers.map((t) => t.shortName).join(', '),
              group: {
                index: group.index ?? 0,
                length: group.length ?? 0,
              },
            });
          });
      } else {
        entries.push({
          start: substitutionSubject.start,
          end: substitutionSubject.end,
          color: subject?.timetableConfig?.color ?? undefined,
          field1: subject?.shortName,
          field2: substitutionSubject.classes.map((c) => c.shortName).join(', '),
          field4: substitutionSubject.teachers.map((t) => t.shortName).join(', '),
        });
      }
    }

    return entries;
  }, [selectedClassEntries, selectedClassIndex]);

  function handleClassSelection(direction: 'right' | 'left') {
    let newIndex = selectedClassIndex;
    if (direction === 'right') {
      newIndex = newIndex + 1 > selectedClassEntries.length - 1 ? 0 : newIndex + 1;
    } else {
      newIndex = newIndex - 1 < 0 ? selectedClassEntries.length - 1 : newIndex - 1;
    }
    setSelectedClassIndex(newIndex);
  }

  function handleRowSelection(classes: SubstitutionDataEntry[]) {
    setSelectedClassEntries(classes);
    setSelectedClassIndex(0);
  }

  function selectSubject(uuid: string) {
    const subject = substitutionSubjects.find((s) => s.uuid === uuid);
    setSelectedSubject(subject ?? null);
  }

  function handleSubstitute(uuid: string) {
    selectSubject(uuid);
    setSubstitutionModalOpen(true);
  }

  function handleCancel(uuid: string) {
    selectSubject(uuid);
    setCancelModalOpen(true);
  }

  function handleCompleteClass(uuid: string) {
    selectSubject(uuid);
    setCompleteClassModalOpen(true);
  }

  function handleWorkOrder(uuid: string) {
    selectSubject(uuid);
    setWorkOrderModalOpen(true);
  }

  function handleDifferentTime(uuid: string) {
    selectSubject(uuid);
    setDifferentTimeModalOpen(true);
  }

  return (
    <div className={styles['plan-stubstitutions']}>
      <ActionBar
        onGlobalFilterChange={() => {}}
        extendedActionsRight={
          <>
            <Button
              hierarchy='tertiary'
              icon={showAll ? <InvisibleIcon /> : <VisibleIcon />}
              onClick={() => {
                setShowAll(!showAll);
              }}
            >
              {showAll ? t('substitutions.showRelevant') : t('common.showAll')}
            </Button>
            <Button hierarchy='tertiary' icon={<UploadIcon />} onClick={() => console.log('TODO: implement')}>
              {t('substitutions.publish')}
            </Button>
          </>
        }
        showPrintButton
        onPrintClick={() => console.log('TODO: implement')}
      />
      <Grid useFormGap>
        <GridRow>
          <GridColumn width={9}>
            <SubstitutionSubjectsTable
              data={showAll ? substitutionSubjects : filteredSubstitutionSubjects}
              onRowSelect={handleRowSelection}
              onSubstitute={handleSubstitute}
              onCancel={handleCancel}
              onCompleteClass={handleCompleteClass}
              onWorkOrder={handleWorkOrder}
              onDifferentTime={handleDifferentTime}
            />
          </GridColumn>
          <GridColumn width={3}>
            <Card>
              <div className={styles.actions}>
                <Button
                  hierarchy='tertiary'
                  icon={<ArrowLeftIcon />}
                  disabled={selectedClassEntries.length <= 1}
                  onClick={() => handleClassSelection('left')}
                />
                <div
                  className={classNames(styles['selected-class'], {
                    [styles.empty]: selectedClassEntries.length === 0,
                  })}
                >
                  {selectedClassEntries[selectedClassIndex]?.name ?? t('substitutions.noClassSelected')}
                </div>
                <Button
                  hierarchy='tertiary'
                  icon={<ArrowRightIcon />}
                  disabled={selectedClassEntries.length <= 1}
                  onClick={() => handleClassSelection('right')}
                />
              </div>
              <TimetableView
                showTimegrid
                start={timetableStart.toDate()}
                end={timetableEnd.toDate()}
                entries={timetableViewEntries}
              />
            </Card>
          </GridColumn>
        </GridRow>
      </Grid>

      {selectedSubject && (
        <SubstitutionModal
          isOpen={substitutionModalOpen}
          originTeachers={selectedSubject.missingTeachers}
          originRooms={selectedSubject.rooms}
          timetableStart={normalizedTimetableStart.toDate()}
          timetableEnd={normalizedTimetableEnd.toDate()}
          subjectStart={selectedSubject.start}
          subjectEnd={selectedSubject.end}
          substitutionSubjects={substitutionSubjects}
          onClose={() => {
            setSubstitutionModalOpen(false);
            setSelectedSubject(null);
          }}
        />
      )}

      {selectedSubject && (
        <CancelSubjectModal
          isOpen={cancelModalOpen}
          onClose={() => {
            setCancelModalOpen(false);
            setSelectedSubject(null);
          }}
        />
      )}

      {selectedSubject && (
        <CompleteClassModal
          isOpen={completeClassModalOpen}
          originTeachers={selectedSubject.missingTeachers}
          originRooms={selectedSubject.rooms}
          onClose={() => {
            setCompleteClassModalOpen(false);
            setSelectedSubject(null);
          }}
        />
      )}

      {selectedSubject && (
        <WorkOrderModal
          isOpen={workOrderModalOpen}
          originTeachers={selectedSubject.missingTeachers}
          originRooms={selectedSubject.rooms}
          onClose={() => {
            setWorkOrderModalOpen(false);
            setSelectedSubject(null);
          }}
        />
      )}

      {selectedSubject && (
        <DifferentTimeModal
          isOpen={differentTimeModalOpen}
          originTeachers={selectedSubject.missingTeachers}
          originRooms={selectedSubject.rooms}
          onClose={() => {
            setDifferentTimeModalOpen(false);
            setSelectedSubject(null);
          }}
        />
      )}
    </div>
  );
});
