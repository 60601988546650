import dayjs, { Dayjs } from 'dayjs';
import { makeAutoObservable, action } from 'mobx';

export class SubstitutionStore {
  public selectedDay: Dayjs = dayjs();

  constructor() {
    makeAutoObservable(this, {
      setSelectedDay: action.bound,
    });
  }

  public setSelectedDay(date: Date | Dayjs) {
    this.selectedDay = dayjs(date);
  }
}
