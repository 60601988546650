import { useTranslation } from 'react-i18next';
import { NavigationTabs } from '../NavigationTabs/NavigationTabs';
import styles from './SubstitutionsTopBar.module.scss';
import { Button, ArrowLeftIcon, DatePicker, ArrowRightIcon } from '@bp/ui-components';
import dayjs from 'dayjs';
import { getDayOfWeekAsString } from '../../utils/dateCalculations';
import { lowercaseFirstLetter } from '../../utils/helper';
import { useUserConfigContext } from '../../hooks/useUserConfigContext';
import { useLocation } from 'react-router-dom';
import { useSubstitutionStore } from '../Substitutions/SubstitutionProvider';
import { observer } from 'mobx-react-lite';

type SubstitutionsTopBarProps = {
  baseSlug: string;
};

export const SubstitutionsTopBar = observer(({ baseSlug }: SubstitutionsTopBarProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;

  const { selectedDay, setSelectedDay } = useSubstitutionStore();

  return (
    <div className={styles['substitutions-top-bar']}>
      <NavigationTabs
        tabs={[
          {
            title: t('absences.title', { count: 2 }),
            path: `/${baseSlug}/${t('routes.absences.slug')}`,
            active: location.pathname.includes(t('routes.absences.slug')),
          },
          {
            title: t('substitutions.title', { count: 2 }),
            path: `/${baseSlug}/${t('routes.substitutions.slug')}`,
          },
          {
            title: t('announcements.title', { count: 2 }),
            path: `/${baseSlug}/${t('routes.announcements.slug')}`,
            disabled: true,
          },
        ]}
        size='xxl'
        hideBorder
        className={styles.tabs}
      />
      <div className={styles.date}>
        <div>{lowercaseFirstLetter(t('absences.for', { for: getDayOfWeekAsString(selectedDay) }))}</div>
        <Button
          name='decrease'
          hierarchy='secondary'
          onClick={() => setSelectedDay(selectedDay.subtract(1, 'day'))}
          icon={<ArrowLeftIcon />}
        />
        <DatePicker
          className={styles.picker}
          onChange={(e) => setSelectedDay(dayjs(e))}
          name='date'
          value={selectedDay.toDate()}
          dense
          minDate={dayjs(currentSchoolYear?.start).toDate()}
          maxDate={dayjs(currentSchoolYear?.end).toDate()}
        />
        <Button
          name='increase'
          hierarchy='secondary'
          onClick={() => setSelectedDay(selectedDay.add(1, 'day'))}
          icon={<ArrowRightIcon />}
        />
      </div>
    </div>
  );
});
