import { Grid, GridRow, Input, ModalBottomButtons } from '@bp/ui-components';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-router-dom';

type CancelSubjectFormProps = {
  onClose: () => void;
};

type CancelSubjectFormType = {
  comment: string;
};

export const CancelSubjectForm = ({ onClose }: CancelSubjectFormProps) => {
  const { t } = useTranslation();

  const initialValues: CancelSubjectFormType = {
    comment: '',
  };

  async function handleSubmit(values: CancelSubjectFormType, formHelpers: FormikHelpers<CancelSubjectFormType>) {
    // TODO: implement
  }

  return (
    <Formik<CancelSubjectFormType> initialValues={initialValues} onSubmit={handleSubmit}>
      {({ setFieldValue, values, isSubmitting, dirty, resetForm }) => {
        return (
          <Form>
            <Grid useFormGap>
              <GridRow>
                <Input
                  name='comment'
                  label={t('common.comment')}
                  onChange={async (e) => setFieldValue('comment', e.target.value)}
                  value={values.comment}
                />
              </GridRow>
            </Grid>

            <ModalBottomButtons
              closeButton={{
                callback: () => {
                  onClose();
                  resetForm();
                },
              }}
              submitButton={{
                disabled: isSubmitting || !dirty,
              }}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
