import dayjs, { Dayjs, OpUnitType } from 'dayjs';
import { isString } from 'formik';
import utc from 'dayjs/plugin/utc';
import { t } from 'i18next';
import { isValideTimeString } from './typeguards';

dayjs.extend(utc);

type UniversalDate = string | Date | Dayjs | undefined | null;

export const isFirstBeforeSecond = (a: UniversalDate, b: UniversalDate, unit: OpUnitType = 'day'): boolean => {
  return dayjs(a).startOf(unit).isBefore(dayjs(b).startOf(unit), unit);
};

export const isFirstAfterSecond = (a: UniversalDate, b: UniversalDate, unit: OpUnitType = 'day'): boolean => {
  return dayjs(a).startOf(unit).isAfter(dayjs(b).startOf(unit), unit);
};

export const isFirstSameOrAfterSecond = (a: UniversalDate, b: UniversalDate, unit: OpUnitType = 'day'): boolean => {
  return dayjs(a).startOf(unit).isSameOrAfter(dayjs(b).startOf(unit), unit);
};

export const isFirstSameOrBeforeSecond = (a: UniversalDate, b: UniversalDate, unit: OpUnitType = 'day'): boolean => {
  return dayjs(a).startOf(unit).isSameOrBefore(dayjs(b).startOf(unit), unit);
};

export const isSame = (a: UniversalDate, b: UniversalDate, unit: OpUnitType = 'day'): boolean => {
  return dayjs(a).startOf(unit).isSame(dayjs(b).startOf(unit));
};

export const isFirstBetweenSecondAndThird = (
  date: UniversalDate,
  a: UniversalDate,
  b: UniversalDate,
  unit: OpUnitType = 'day',
  d: '()' | '[]' | '[)' | '(]' = '[]',
): boolean => {
  return dayjs(date).startOf(unit).isBetween(dayjs(a).startOf(unit), dayjs(b).startOf(unit), unit, d);
};

export const getDaysDifference = (a: UniversalDate, b: UniversalDate, including = true): number => {
  const diff = dayjs(b).startOf('day').diff(dayjs(a).startOf('day'), 'days');
  return including ? diff + 1 : diff;
};

export const getMinutesDifference = (a: UniversalDate, b: UniversalDate): number => {
  const normA = normalizedDate(dayjs(a));
  const normB = normalizedDate(dayjs(b));
  return Math.abs(dayjs(normB).diff(dayjs(normA), 'minutes'));
};

export const niceDate = (
  date: UniversalDate,
  dateStyle?: 'medium' | 'full' | 'long' | 'short' | undefined,
  timeStyle?: 'medium' | 'full' | 'long' | 'short' | undefined,
) => {
  if (isString(date)) {
    const event = new Date(date);

    if (dateStyle && timeStyle) {
      return event.toLocaleString('de-DE', { dateStyle: dateStyle, timeStyle: timeStyle });
    }
    return dateStyle ? event.toLocaleString('de-DE', { dateStyle: dateStyle }) : event.toLocaleString('de-DE');
  } else if (date instanceof Date) {
    if (dateStyle && timeStyle) {
      return date.toLocaleString('de-DE', { dateStyle: dateStyle, timeStyle: timeStyle });
    }
    return dateStyle ? date.toLocaleString('de-DE', { dateStyle: dateStyle }) : date.toLocaleString('de-DE');
  }
};

export const combineFirstDateWithSecondTime = (a: UniversalDate, b: UniversalDate): Date => {
  const date = dayjs(a).format('YYYY-MM-DD');
  const time = dayjs(b).format('HH:mm:ss');
  return dayjs(`${date}T${time}`).toDate();
};

export const getDayOfWeekAsString = (date: UniversalDate): string => {
  switch (dayjs(date).weekday()) {
    case 0:
      return t('days.sunday');
    case 1:
      return t('days.monday');
    case 2:
      return t('days.tuesday');
    case 3:
      return t('days.wednesday');
    case 4:
      return t('days.thursday');
    case 5:
      return t('days.friday');
    case 6:
      return t('days.saturday');
    default:
      return '';
  }
};

export const setTimeOnDate = (date: UniversalDate, time: string): Date => {
  if (!isValideTimeString(time)) {
    throw new Error('Invalid time string format');
  }

  return dayjs(date)
    .set('hour', parseInt(time.split(':')[0]))
    .set('minute', parseInt(time.split(':')[1]))
    .toDate();
};

export const normalizedDate = (date: Date | Dayjs, relativeTo?: Date | Dayjs): Dayjs => {
  const relative = dayjs(relativeTo ?? undefined);
  return dayjs(date)
    .set('year', relative.year())
    .set('month', relative.month())
    .week(relative.week())
    .set('day', relative.day());
};
